import React from 'react';

import LogoImage from '../../assets/austrian-logo.png';
import './styles.scss';

const Logo: React.FC = () => (
  <a className="upper-header-container" href="https://www.austrian.com/">
    <img className="logo" src={LogoImage} alt="Austrian Airlines logo" />
  </a>
);

export default Logo;
